import { useState, useEffect } from "react"
import { GroupFieldConfig, GroupFieldValue, FieldValue } from "./types"
import Field from "./field"

interface GroupFieldProps {
  id: string
  config: GroupFieldConfig
  onChange: (id: string, value: GroupFieldValue) => void
  value?: GroupFieldValue
}

const GroupField = ({ id, config, onChange, value }: GroupFieldProps) => {
  const { layout, fields } = config
  const [group, setGroup] = useState<GroupFieldValue>(value)

  useEffect(() => {
    setGroup(value)
  }, [value])

  const handleFieldChange = (fieldId: string, fieldValue: FieldValue) => {
    const newGroup = { ...group, [fieldId]: fieldValue }
    setGroup(newGroup)
    onChange(id, newGroup)
  }

  const getUniqueId = (id: string, fieldId: string) => {
    return `${id}.${fieldId}`
  }

  const isHorizontal = layout === "horizontal"
  const fieldCount = Object.keys(fields).length

  return (
    <div className={`flex ${isHorizontal ? "flex-row" : "flex-col"} gap-4`}>
      {Object.entries(fields).map(([fieldId, fieldConfig]) => (
        <div key={fieldId} className={isHorizontal ? `w-1/${fieldCount}` : "w-full"}>
          <Field
            id={getUniqueId(id, fieldId)}
            config={fieldConfig}
            onChange={(id, value) => handleFieldChange(fieldId, value as FieldValue)}
            value={group?.[fieldId]}
            isGroupField={true}
          />
        </div>
      ))}
    </div>
  )
}

export default GroupField
