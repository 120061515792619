import React, { useEffect, useCallback, ReactNode } from "react"
import { FormProvider } from "./context"
import { FormProps } from "./types"
import { useFormContext } from "./context"
import { setBehaviorState } from "./actions"
import { applyBehaviors } from "./behavior"
import { isObjectsEqual } from "@common/lib/util"
import DefaultLayout from "@common/v2/form/layouts/default"
import Submit from "@common/v2/form/submit"
import Reset from "@common/v2/form/reset"

const Form = ({
  behaviors,
  fields,
  order,
  children,
  size = "sm",
  onStateChange,
  logResponse = false,
  submitFunction,
  onSubmit,
  onSubmitResponse,
  onSubmitSuccess,
  onSubmitFail,
  initialFormState,
  onActionClick,
}: FormProps) => {
  return (
    <FormProvider
      fields={fields}
      order={order}
      behaviors={behaviors}
      size={size}
      onStateChange={onStateChange}
      submitFunction={submitFunction}
      onSubmit={onSubmit}
      onSubmitResponse={onSubmitResponse}
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFail={onSubmitFail}
      logResponse={logResponse}
      initialFormState={initialFormState}
      onActionClick={onActionClick}
    >
      <FormContent>{children}</FormContent>
    </FormProvider>
  )
}

const FormContent = ({ children }: { children: React.ReactNode }) => {
  const { state, dispatch, behaviors } = useFormContext()
  // const { formState } = state

  //TODO:this should be done on filed's value change, now parent needs to wrap onStateChange callback into `useCallback` hook to use `onStateChange`
  // useEffect(() => {
  //   const unflattenedState = unflattenObject(formState)
  //   onStateChange?.(unflattenedState as FormState)
  // }, [formState, onStateChange])

  const applyBehaviorsEffect = useCallback(() => {
    const newBehaviorState = applyBehaviors(
      behaviors,
      state.formState,
      state.behaviorState
    )
    if (!isObjectsEqual(newBehaviorState, state.behaviorState))
      dispatch(setBehaviorState(newBehaviorState))
  }, [behaviors, state.formState, state.behaviorState, dispatch])

  useEffect(() => {
    applyBehaviorsEffect()
  }, [applyBehaviorsEffect])

  return (
    <form method="POST" className="w-full">
      {children ? (
        children
      ) : (
        <>
          <Layout>
            <DefaultLayout />
          </Layout>
          <div className="mt-4">
            <Submit>Save</Submit>
          </div>
        </>
      )}
    </form>
  )
}

const Layout = ({ children }: { children?: ReactNode }) => {
  return <> {children} </>
}

export { Layout, Submit, Reset }
export default Form
