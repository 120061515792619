import React, { useState, useEffect } from "react"
import {
  MultiGroupFieldConfig,
  GroupFieldConfig,
  GroupFieldValue,
  MultiGroupFieldValue,
  Fields,
  ValidationErrors,
  FormStateFlat,
} from "./types"
import GroupField from "./groupField"
import Button from "@common/buttons/button"
import { isNullOrEmpty, isObjectsEqual } from "@common/lib/util"
import Svg from "@common/svg"
import TooltipWrapper from "@common/tooltip/tooltipWrapper"
import { validateField } from "./validations"
import { getValue } from "@common/lib/flattenUnflattenObjects"
// export interface FieldStateFlat {
//   errors?: ValidationErrors
//   values?: FormStateFlat
// }

interface MultiGroupFieldProps {
  id: string
  config: MultiGroupFieldConfig
  onChange: (
    id: string,
    value: MultiGroupFieldValue,
    errors?: ValidationErrors,
    isAction?: boolean
  ) => void
  value?: MultiGroupFieldValue
}

function getUniqueId(id: string, index: number) {
  return `${id}[${index}]`
}

function getChildFieldId(parentId: string, fieldId: string) {
  return `${parentId}.${fieldId}`
}

/**
 *
 * @description returns flattened initial state and Validation errors for MultiGroup Field
 */
function getInitialState(baseId: string, config: MultiGroupFieldConfig) {
  const errors: ValidationErrors = {}
  const values: FormStateFlat = {}
  if ("fields" in config) {
    const minItems = config.minItems || 1
    for (let i = 0; i < minItems; i++) {
      const parentId = getUniqueId(baseId, i)
      for (const [fieldId, field] of Object.entries(config.fields)) {
        const fullFieldId = getChildFieldId(parentId, fieldId)
        // if individual field has value then use it or look into parent's (MultiGroupFieldConfig) config
        // config.value will be an array so we have to access it using [index].fieldId
        const value = field["value"] || getValue(config.value, `[${i}].${fieldId}`)
        const error = validateField(field, value)
        values[fullFieldId] = value
        if (error) errors[fullFieldId] = { ...error, touched: false }
      }
    }
  }
  return {
    values,
    errors,
  }
}

/**
 *
 * @description returns flattened Validation errors for MultiGroup Field
 */
function validateArrayValues(
  baseId: string,
  values: MultiGroupFieldValue,
  config: MultiGroupFieldConfig
) {
  const errors: ValidationErrors = {}
  const state: FormStateFlat = {}

  if (!config.fields) return { errors, values: state }

  values.forEach((value, index) => {
    for (const [fieldId, field] of Object.entries(config.fields)) {
      const fieldValue = value[fieldId]
      const error = validateField(field, fieldValue)
      const fullFieldId = getChildFieldId(getUniqueId(baseId, index), fieldId)
      errors[fullFieldId] = error
      state[fullFieldId] = fieldValue
    }
  })

  return { errors }
}
/**
 *
 * @description returns flattened state for removed group
 */
function getStateForRemovedGroup(
  baseId: string,
  config: MultiGroupFieldConfig,
  removeIndex?: number
) {
  const errors: ValidationErrors = {}
  const values: FormStateFlat = {}

  for (const [fieldId] of Object.entries(config.fields)) {
    const fullFieldId = getChildFieldId(getUniqueId(baseId, removeIndex), fieldId)
    errors[fullFieldId] = undefined
    values[fullFieldId] = undefined
  }

  return { errors, values }
}

const MultiGroupField = ({ id, config, onChange, value }: MultiGroupFieldProps) => {
  const { fields, minItems = 1, maxItems } = config
  const [groups, setGroups] = useState<any[]>(
    !isNullOrEmpty(value) ? value : [createEmptyGroup(fields)]
  )

  useEffect(() => {
    if (!isObjectsEqual(value, groups))
      setGroups(!isNullOrEmpty(value) ? value : [createEmptyGroup(fields)])
  }, [fields, value])

  const handleFieldChange = (
    index: number,
    fieldId: string,
    fieldValue: GroupFieldValue
  ) => {
    const newGroups = groups.map((group, i) =>
      i === index ? { ...group, ...fieldValue } : group
    )
    const { errors } = validateArrayValues(id, newGroups, config)
    setGroups(newGroups)
    onChange(id, newGroups, errors, false)
  }

  function createEmptyGroup(fields: Fields): GroupFieldValue {
    return Object.keys(fields).reduce((acc, key) => {
      acc[key] = undefined
      return acc
    }, {} as GroupFieldValue)
  }

  const addGroup = (index: number) => {
    if (maxItems && groups.length >= maxItems) return
    const newGroups = [
      ...groups.slice(0, index + 1),
      createEmptyGroup(fields),
      ...groups.slice(index + 1),
    ]
    const { errors } = validateArrayValues(id, newGroups, config)
    setGroups(newGroups)
    onChange(id, newGroups, errors, true)
  }

  const removeGroup = (index: number) => {
    if (groups.length > minItems) {
      const newGroups = groups.filter((_, i) => i !== index)
      const { errors } = getStateForRemovedGroup(id, config, index)
      setGroups(newGroups)
      onChange(id, newGroups, errors, true)
    }
  }

  const clearGroup = (index: number) => {
    const newGroups = groups.map((group, i) =>
      i === index ? createEmptyGroup(fields) : group
    )
    const { errors } = validateArrayValues(id, newGroups, config)
    setGroups(newGroups)
    onChange(id, newGroups, errors, true)
  }

  const groupFieldConfig: GroupFieldConfig = {
    element: "group",
    fields,
    layout: config.layout,
  }

  return (
    <div className="space-y-4">
      {groups.map((group, index) => (
        <fieldset key={index} className="border p-4 rounded-md">
          <legend className="text-sm font-medium text-right">
            <div className="flex items-center gap-2">
              <TooltipWrapper content="Clear" position="bottom">
                <Button
                  variant="outline"
                  onClick={(e) => {
                    e.preventDefault()
                    clearGroup(index)
                  }}
                  type="button"
                >
                  <Svg name="refresh" classes="h-3 w-3" />
                </Button>
              </TooltipWrapper>

              {groups.length > minItems && (
                <TooltipWrapper content="Delete" position="bottom">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      removeGroup(index)
                    }}
                  >
                    <Svg name="delete" classes="h-3 w-3" />
                  </Button>
                </TooltipWrapper>
              )}
              {(!maxItems || groups.length < maxItems) && (
                <TooltipWrapper content="Add" position="bottom">
                  <Button
                    variant="outline"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      addGroup(index)
                    }}
                  >
                    <Svg name="plus" classes="h-3 w-3" />
                  </Button>
                </TooltipWrapper>
              )}
            </div>
          </legend>
          <GroupField
            id={getUniqueId(id, index)}
            config={groupFieldConfig}
            onChange={(id, value) => handleFieldChange(index, id, value)}
            value={group}
          />
        </fieldset>
      ))}
    </div>
  )
}

/**
 * @private
 */
MultiGroupField.getInitialState = getInitialState

export default MultiGroupField
