import React, { useState, useRef, useEffect, useCallback } from "react"
import Tag from "@common/tags/tag"
import { joinClassNames } from "@common/lib/util"
import { VariantProps, cva } from "class-variance-authority"
import Svg from "@common/svg"
import Button from "@common/buttons/button"

interface TagValue {
  value: string
  isValid: boolean
}

interface MultiInputProps extends VariantProps<typeof InputVariants> {
  placeholder?: string
  value?: string[]
  onChange?: (value: string[]) => void
  onFocus?: () => void
  onBlur?: () => void
  id?: string
  size?: "sm" | "xs"
  maxTags?: number
  validateTag?: (tag: string) => boolean
  onExceedMaxTags?: () => void
  disabled?: boolean
  addOnBlur?: boolean
  allowDuplicates?: boolean
}

const InputVariants = cva("input", {
  variants: {
    size: {
      sm: "size-sm",
      xs: "size-xs",
    },
  },
  defaultVariants: {
    size: "xs",
  },
})

const MultiInput = ({
  placeholder,
  value = [],
  onChange,
  onFocus,
  onBlur,
  id,
  size,
  maxTags,
  validateTag,
  onExceedMaxTags,
  disabled = false,
  addOnBlur = true,
  allowDuplicates = false,
}: MultiInputProps) => {
  const [text, setText] = useState<string>("")
  const [multiInputValues, setMultiInputValues] = useState<TagValue[]>([])
  const inputRef = useRef<any>(null)
  const containerRef = useRef<any>(null)

  useEffect(() => {
    if (value.length > 0 && multiInputValues.length === 0) {
      const values: TagValue[] = value.map((val) => ({
        value: val,
        isValid: validateTag ? validateTag(val) : true,
      }))
      setMultiInputValues(values)
    }
  }, [value, validateTag])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = text.trim()
    if (e.key === "Backspace") {
      if (!text && multiInputValues.length > 0) {
        e.preventDefault()
        handleRemove(multiInputValues.at(-1))
      } else if (!text && multiInputValues.length === 1) {
        e.preventDefault()
        handleRemove(multiInputValues[0])
      }
    }
    const isKeyMatched = ["Tab", "Enter", ","].includes(e.key)
    if (trimmedValue && isKeyMatched) {
      e.preventDefault()
      handleMultiInputValue(trimmedValue)
    }
  }

  const handleRemove = useCallback(
    (tag: TagValue | undefined) => {
      if (!tag) return
      const newValues = multiInputValues.filter((obj) => obj.value !== tag.value)
      setMultiInputValues(newValues)
      if (onChange) {
        onChange(newValues.map((obj) => obj.value))
      }
    },
    [multiInputValues, onChange]
  )

  const handleMultiInputValue = (value: string) => {
    if (maxTags && multiInputValues.length >= maxTags) {
      onExceedMaxTags?.()
      return
    }

    const isDuplicate = multiInputValues.some((v) => v.value === value)
    if (!isDuplicate || allowDuplicates) {
      const isValid = validateTag ? validateTag(value) : true
      const newValues = [...multiInputValues, { value, isValid }]
      setMultiInputValues(newValues)
      setText("")
      if (onChange) {
        onChange(newValues.map((obj) => obj.value))
      }
    }
  }

  const handleChange = (input: string) => {
    setText(input)
  }

  const handleMultiInputFocus = () => {
    if (!disabled) {
      inputRef?.current?.focus()
    }
    onFocus?.()
  }

  const handleBlur = (e: any) => {
    // Only process blur if it's not moving focus to the container
    if (e.relatedTarget !== containerRef.current) {
      if (addOnBlur) {
        const trimmedValue = text.trim()
        if (trimmedValue) {
          handleMultiInputValue(trimmedValue)
        }
      }
    }
    onBlur?.()
  }

  const handleClearAll = () => {
    setMultiInputValues([])
    if (onChange) {
      onChange([])
    }
    inputRef.current?.focus()
  }

  const handleContainerFocus = (e: React.FocusEvent) => {
    if (e.target === containerRef.current) {
      inputRef.current?.focus()
    }
  }

  const classes = joinClassNames(
    InputVariants({ size }),
    "py-0 px-0",
    disabled ? "opacity-50 cursor-not-allowed" : ""
  )
  const isEmpty = multiInputValues.length === 0

  return (
    <div
      ref={containerRef}
      className={classes}
      onClick={handleMultiInputFocus}
      onFocus={handleContainerFocus}
      tabIndex={disabled ? -1 : 0}
      aria-label="Multi-value input"
    >
      <ul className="flex flex-wrap items-center w-full shrink-0 min-w-0 h-full gap-x-1 gap-y-1">
        {multiInputValues.map((tag) => (
          <li key={tag.value}>
            <Tag
              color={tag.isValid ? "transparent" : "red"}
              cross
              onCrossClick={() => handleRemove(tag)}
            >
              {tag.value}
            </Tag>
          </li>
        ))}
        <li className="flex-grow">
          <input
            ref={inputRef}
            value={text}
            onChange={(e) => handleChange(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            className="w-full shrink focus:outline-none bg-transparent min-w-0 max-w-[12rem] focus:border-0 focus:ring-0 border-0 h-full py-0 px-0"
            placeholder={isEmpty ? placeholder : ""}
            id={id}
            disabled={
              disabled ||
              (maxTags !== undefined && multiInputValues.length >= maxTags)
            }
            aria-label="Add new tag"
          />
        </li>
        {!isEmpty && (
          <li>
            <Button variant="secondary" onClick={handleClearAll}>
              <Svg name="cross" classes="h-4 w-4" />
            </Button>
          </li>
        )}
      </ul>
    </div>
  )
}

export default MultiInput
