export default function EmptyResult({
  message = "No Match Found",
}: {
  message?: string
}) {
  return (
    <div className="menu-item bg-muted select-none">
      <div className="flex items-center">{message}</div>
    </div>
  )
}
