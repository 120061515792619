// React imports
import { ChangeEvent, useEffect, useRef, useState } from "react"
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react"
import Loader from "@common/loader"
import Tag from "@common/tags/tag"
import Tags from "@common/tags/tags"
import Svg from "@common/svg"
import { VariantProps, cva } from "class-variance-authority"

import {
  isArray,
  isArraysEqual,
  isFunction,
  isNullOrEmpty,
  joinClassNames,
} from "@common/lib/util"
import {
  DropDownOption as TDropDownOption,
  DropDownOptions,
} from "@common/v2/dropdowns/types"
import { filterOptions, getListedOptionClasses } from "@common/v2/dropdowns/utils"
import DropDownOption from "@common/v2/dropdowns/dropDownOption"
import OptionHeader from "@common/v2/dropdowns/optionHeader"
import EmptyResult from "@common/v2/dropdowns/emptyResult"

type ComboboxStyledBaseProps = {
  singleSelectMode?: boolean
  maxSelectCount?: number
  minSelectCount?: number
  selectedKeys?: Array<string>
  options?: DropDownOptions
  placeholder?: string
  onSelect?: (selectedKeys: Array<string>) => void
  disabled?: boolean
  id?: string
  /**@private */
  onInputChange?: (inputValue: string) => void
}

const ComboBoxVariants = cva(
  "bg-transparent border-none group text-primary focus:ring-0 px-0 w-full border rounded-md focus:outline-none placeholder:text-muted-foreground bg-muted ",
  {
    variants: {
      size: {
        sm: "size-sm",
        xs: "size-xs",
      },
    },
    defaultVariants: {
      size: "xs",
    },
  }
)
export interface ComboboxStyledProps
  extends ComboboxStyledBaseProps,
    VariantProps<typeof ComboBoxVariants> {}

export default function ComboboxStyled({
  placeholder = "search or select",
  options,
  onSelect,
  selectedKeys = [],
  disabled = false,
  singleSelectMode = false,
  maxSelectCount,
  size = "sm",
  onInputChange,
}: ComboboxStyledProps) {
  const [query, setQuery] = useState<string>()
  const [isMaxed, setIsMaxed] = useState<boolean>(false)
  const [selectedOptions, setSelectedOptions] = useState<Array<TDropDownOption>>(
    selectedKeys.map((key) => options?.[key]).filter(Boolean)
  )
  const inputRef = useRef<any>(null)

  useEffect(() => {
    updateIsMaxed(selectedKeys)
    const updatedSelectedOptions = selectedKeys
      .map((key) => options?.[key])
      .filter(Boolean)
    if (
      !isArraysEqual(
        selectedOptions.map((opt) => opt.key),
        selectedKeys
      )
    ) {
      setSelectedOptions(updatedSelectedOptions)
    }
  }, [selectedKeys, maxSelectCount, options])

  function updateIsMaxed(keys: Array<string>) {
    setIsMaxed(maxSelectCount ? maxSelectCount <= keys.length : false)
  }

  const handleRemove = (key: any) => {
    if (disabled) return
    const newSelectedOptions = selectedOptions.filter((option) => option.key != key)
    const keys = newSelectedOptions.map((opt) => opt.key)
    updateIsMaxed(keys)
    setSelectedOptions(newSelectedOptions)
    if (onSelect) onSelect(keys)
  }

  const handleKeyDown = (e: any) => {
    if (e.key == "Backspace" && !query && !disabled && !singleSelectMode) {
      const newSelectedOptions = selectedOptions.slice(0, -1)
      const keys = newSelectedOptions.map((opt) => opt.key)
      updateIsMaxed(keys)
      setSelectedOptions(newSelectedOptions)
      if (isFunction(onSelect)) onSelect(keys)
    }
  }

  const handleChange = (selected: TDropDownOption | Array<TDropDownOption>) => {
    let newSelectedOptions: Array<TDropDownOption> = []
    // If multiple select, we receive an array of options
    if (isArray(selected)) {
      newSelectedOptions = selected
    } else if (selected) {
      // For single select, we normalize it as an array of one option
      newSelectedOptions = [selected]
    }
    const keys = newSelectedOptions.map(({ key }) => key)
    const isMaxed = maxSelectCount ? maxSelectCount < keys.length : false
    updateIsMaxed(keys)

    if (!isMaxed) {
      setSelectedOptions(newSelectedOptions)
      onSelect?.(keys)
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isFunction(onInputChange)) onInputChange(e.target.value)
    setQuery(e.target.value)
  }

  const containerClass = joinClassNames(
    "flex flex-col bg-transparent text-primary rounded-md relative w-full border text-left text-sm transition-colors delay-75",
    singleSelectMode ? " " : "px-2",
    disabled ? " opacity-50 cursor-not-allowed " : " cursor-pointer "
  )

  const clearQuery = () => {
    setQuery(undefined)
    if (isFunction(onInputChange)) onInputChange(undefined)
    setTimeout(() => {
      if (inputRef.current) inputRef.current.value = ""
    }, 0)
  }

  const hasOptions = !isNullOrEmpty(options)

  const filteredOptions = filterOptions(options, query)
  const selectedOption = selectedOptions?.at(0)
  const variantsClass = ComboBoxVariants({ size })
  return (
    <>
      <Combobox
        value={singleSelectMode ? selectedOptions[0] : selectedOptions}
        onChange={handleChange}
        multiple={singleSelectMode ? false : true}
        //ignore this type error, it's working on build
        //@ts-ignore
        by="key"
        immediate
        disabled={disabled}
        onClose={clearQuery}
      >
        {({ open }) => {
          const isInputVisible = !(singleSelectMode && !open && selectedOption)
          return (
            <>
              <div className={containerClass}>
                {selectedOptions?.length > 0 && !singleSelectMode && hasOptions && (
                  <div
                    className="py-2"
                    onFocus={(e) => {
                      //Prevent focus on input when tags are clicked for remove
                      e.stopPropagation()
                    }}
                  >
                    <Tags maxShowTagLimit={10} isClickType={false}>
                      {selectedOptions.map((option) => (
                        <Tag
                          key={option.key}
                          cross={true}
                          onCrossClick={() => handleRemove(option.key)}
                          size={size == "xs" ? "xs" : "default"}
                        >
                          {option.display}
                        </Tag>
                      ))}
                    </Tags>
                  </div>
                )}

                {!options ? (
                  <div className={variantsClass}>
                    <Loader />
                  </div>
                ) : (
                  <>
                    <div
                      className={joinClassNames(
                        "flex justify-start gap-2 items-center",
                        singleSelectMode ? "px-2 py-0" : ""
                      )}
                    >
                      {open && (
                        <Svg
                          classes="w-4 h-4 animate-loadFadeUp text-muted-foreground"
                          name="search"
                        />
                      )}

                      <ComboboxInput
                        className={joinClassNames(
                          variantsClass,
                          !isInputVisible && "absolute inset-0 opacity-0"
                        )}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder={placeholder}
                        displayValue={(option: any) => option?.display}
                        onFocus={clearQuery}
                        ref={inputRef}
                        disabled={!hasOptions || disabled}
                      />
                      {!isInputVisible && (
                        <ComboboxButton
                          className={joinClassNames(variantsClass, "z-10")}
                        >
                          <div className="w-fit">
                            <DropDownOption
                              icon={selectedOption?.icon}
                              display={selectedOption?.display}
                            />
                          </div>
                        </ComboboxButton>
                      )}
                      <ComboboxButton>
                        <Svg
                          name="arrow-up-down"
                          classes="w-4 h-4 animate-loadFadeUp text-muted-foreground"
                        />
                      </ComboboxButton>
                    </div>
                  </>
                )}
              </div>

              <ComboboxOptions
                as="div"
                className="list-option w-[calc(var(--input-width)+64px)] relative" // padding between button and input = 52px
                anchor="bottom"
              >
                {isNullOrEmpty(filteredOptions) ? (
                  <EmptyResult />
                ) : (
                  Object.keys(filteredOptions).map((key) => {
                    const option = filteredOptions[key]
                    if (option.options) {
                      return (
                        <div key={option.key}>
                          <OptionHeader display={option.display} />
                          {Object.keys(option.options).map((subKey) => {
                            const subOption = option.options[subKey]
                            return renderComboboxOption(subOption)
                          })}
                        </div>
                      )
                    }
                    return renderComboboxOption(option)
                  })
                )}
              </ComboboxOptions>
            </>
          )
        }}
      </Combobox>
    </>
  )

  function getIsDisabled(option: TDropDownOption) {
    return (
      option.disabled ||
      (isMaxed && !selectedOptions.some(({ key }) => key === option.key))
    )
  }

  function renderComboboxOption(option: TDropDownOption) {
    const isDisabled = getIsDisabled(option)
    return (
      <ComboboxOption
        key={option.key}
        className={({ focus }) => getListedOptionClasses(focus, isDisabled, size)}
        disabled={isDisabled}
        value={option}
      >
        {({ selected }) => (
          <div className="flex justify-start gap-2 items-center w-full">
            <DropDownOption
              icon={option.icon}
              display={option.display}
              selected={selected}
              showCheckbox={!singleSelectMode}
            />
          </div>
        )}
      </ComboboxOption>
    )
  }
}
