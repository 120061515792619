import React, { useState, useEffect } from "react"
import { joinClassNames } from "@common/lib/util"
import { VariantProps, cva } from "class-variance-authority"

interface InputBaseProps {
  field?: "input" | "textarea"
  type?: string
  placeholder?: string
  value?: string
  id?: string
  onChange?: (value: any) => void
  onFocus?: () => void
  onBlur?: () => void
  autoComplete?: string
  rows?: number
  fieldClasses?: string
  fieldStyles?: React.CSSProperties
  pattern?: string
  disabled?: boolean
  accept?: string
  min?: number
  max?: number
}

const InputVariants = cva("input", {
  variants: {
    size: {
      sm: "size-sm",
      xs: "size-xs",
    },
  },
  defaultVariants: {
    size: "xs",
  },
})

export interface InputProps
  extends InputBaseProps,
    VariantProps<typeof InputVariants> {}

export default function Input({
  field = "input",
  type,
  placeholder,
  value,
  onChange,
  onFocus,
  onBlur,
  autoComplete,
  rows = 4,
  pattern,
  disabled = false,
  size,
  accept,
  id,
  min,
  max,
}: InputProps) {
  const [text, setText] = useState<string>(value || "")

  useEffect(() => {
    if (text !== value) {
      setText(type === "number" ? value : value || "")
    }
  }, [value, type])

  function handleChange(input: any) {
    if (type === "file") onChange?.(input)
    else {
      let newValue =
        type === "number" && !isNaN(input) && input !== "" ? Number(input) : input
      if (type === "number" && newValue === "") newValue = undefined
      setText(newValue)
      if (onChange) onChange(newValue)
    }
  }

  const classes = joinClassNames(InputVariants({ size }))

  if (field === "textarea") {
    return (
      <textarea
        value={text ?? ""}
        rows={rows}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        id={id}
        autoComplete={autoComplete || type}
        placeholder={placeholder}
        className={classes}
      />
    )
  }

  return (
    <input
      value={type === "file" ? undefined : text ?? ""}
      onChange={(e) =>
        handleChange(type === "file" ? e.target.files : e.target.value)
      }
      id={id}
      type={type}
      autoComplete={autoComplete || type}
      placeholder={placeholder}
      className={classes}
      onFocus={onFocus}
      onBlur={onBlur}
      pattern={pattern}
      disabled={disabled}
      accept={accept}
      min={min}
      max={max}
    />
  )
}
