import { useState, useEffect } from "react"
import { Options } from "@common/types"
import { joinClassNames } from "@common/lib/util"

const Chips = ({
  id,
  options,
  value,
  onChange,
  disabled = false,
}: {
  id: string
  options: Options
  value?: Array<string | number>
  onChange: (value: Array<string | number>) => void
  disabled?: boolean
}) => {
  const [selected, setSelected] = useState(value)

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleClick = (key: string | number) => {
    if (disabled) return

    const newSelected = selected?.includes(key)
      ? selected.filter((v) => v !== key)
      : [...(selected || []), key]
    setSelected(newSelected)
    onChange?.(newSelected)
  }

  return (
    <div className="flex flex-wrap gap-2">
      {Object.entries(options).map(([key, option]) => (
        <button
          key={`${id}+${key}`}
          onClick={() => handleClick(key)}
          className={joinClassNames(
            "px-3 py-1 rounded-full text-sm",
            selected?.includes(key)
              ? "bg-primary text-primary-foreground"
              : "border",
            disabled ? "disabled" : ""
          )}
        >
          {option.display}
        </button>
      ))}
    </div>
  )
}
export default Chips
