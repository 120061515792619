import { useCallback } from "react"
import { useFormContext } from "./context"
import { resetForm } from "./actions"
import Button, { ButtonProps } from "@common/buttons/button"

const Reset = ({ children, ...props }: ButtonProps) => {
  const { dispatch, initialState, size } = useFormContext()

  const handleReset = useCallback(
    (e: any) => {
      e.preventDefault()
      dispatch(resetForm(initialState))
    },
    [dispatch]
  )

  return (
    <Button variant="outline" size={size} onClick={handleReset} {...props}>
      {children ? children : "Reset"}
    </Button>
  )
}

export default Reset
