/* flatten & unflatten objects */

export type FlattenedObject = { [key: string]: any }

export function flattenObject(
  obj: any,
  prefix = "",
  result: FlattenedObject = {}
): FlattenedObject {
  if (typeof obj !== "object" || obj === null) {
    result[prefix] = obj
    return result
  }
  if (Array.isArray(obj)) {
    if (obj.some((item) => typeof item === "object" && item !== null)) {
      obj.forEach((item, index) => {
        const newPrefix = prefix ? `${prefix}[${index}]` : `[${index}]`
        flattenObject(item, newPrefix, result)
      })
    } else {
      result[prefix] = obj
    }
  } else {
    Object.keys(obj).forEach((key) => {
      const newPrefix = prefix ? `${prefix}.${key}` : key
      flattenObject(obj[key], newPrefix, result)
    })
  }
  return result
}

function parsePath(key: string): Array<string | number> {
  const parts: Array<string | number> = []
  const regex = /([^[\].]+)|\[(\d+)\]/g
  let match
  while ((match = regex.exec(key)) !== null) {
    if (match[1] !== undefined) {
      parts.push(match[1])
    } else if (match[2] !== undefined) {
      parts.push(parseInt(match[2], 10))
    }
  }
  return parts
}

export function unflattenObject(obj: FlattenedObject): any {
  const result: any = {}
  for (const key in obj) {
    const path = parsePath(key)
    let current = result
    for (let i = 0; i < path.length; i++) {
      const part = path[i]
      if (i === path.length - 1) {
        current[part] = obj[key]
        continue
      }
      const nextPart = path[i + 1]
      const isNextArrayIndex = typeof nextPart === "number"
      if (isNextArrayIndex) {
        if (!current[part]) {
          current[part] = []
        }
      } else {
        if (!current[part]) {
          current[part] = {}
        }
      }
      current = current[part]
    }
  }
  return result
}

export function getValue(object: any, path: string | Array<string | number>) {
  if (!object) return
  const parts = Array.isArray(path) ? path : parsePath(path)
  let index = 0
  const { length } = parts

  while (index < length && object != null) {
    object = object[parts[index++] as keyof typeof object]
  }

  return index && index === length ? object : undefined
}
