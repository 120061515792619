import { useState } from "react"
import Button, { ButtonProps } from "@common/buttons/button"
import { NetworkResponse, Status } from "@common/types"

export interface ButtonSubmitProps extends ButtonProps {
  submitFunction?: () => Promise<NetworkResponse>
  onSubmit?: () => void
  onSubmitResponse?: (response: NetworkResponse) => void
  onSubmitSuccess?: (response: NetworkResponse) => void
  onSubmitFail?: (response: NetworkResponse) => void
}

export default function ButtonSubmit({
  children,
  submitFunction,
  onSubmit,
  onSubmitResponse,
  onSubmitSuccess,
  onSubmitFail,
  ...buttonProps
}: ButtonSubmitProps) {
  const [isLoading, setIsLoading] = useState(false)

  async function submit(e: any) {
    e.preventDefault()
    setIsLoading(true)
    onSubmit?.()
    if (submitFunction) {
      const response = await submitFunction()
      setIsLoading(false)
      onSubmitResponse?.(response)
      if (response?.status === Status.Success || response?.status === Status.Otp)
        onSubmitSuccess?.(response)
      else onSubmitFail?.(response)
    }
  }

  return (
    <Button {...buttonProps} isLoading={isLoading} onClick={submit}>
      {children}
    </Button>
  )
}
