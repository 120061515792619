import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  Dispatch,
  useEffect,
  useState,
} from "react"
import { DispatchAction } from "@common/types"
import { FieldConfig, FormProps, FormState, MultiGroupFieldConfig } from "./types"
import { debounce, isNullOrEmpty, isObjectsEqual } from "@common/lib/util"
import { FormStateFlat, ValidationErrors, Fields, BehaviorState } from "./types"
import { reducer } from "./reducer"
import { validateField } from "./validations"
import { flattenObject } from "@common/lib/flattenUnflattenObjects"
import {
  setFormErrors,
  setFormState,
  setOrderedFields,
} from "@common/v2/form/actions"
import MultiGroupField from "./multiGroupField"
import useDebouncedCallback from "@common/hooks/useDebounceCallback"

const DEBOUNCE_DELAY = 300

export interface State {
  formState: FormStateFlat
  errors: ValidationErrors
  orderedFields: string[]
  behaviorState: BehaviorState
  isFormValid: boolean
}

type FormContextType = {
  initialState: State
  state: State
  dispatch: Dispatch<DispatchAction>
  debouncedDispatch: (action: DispatchAction) => void
  debouncedStateChange: FormProps["onStateChange"]
} & Omit<FormProps, "children">

const FormContext = createContext<FormContextType | undefined>(undefined)

export const useFormContext = () => {
  const context = useContext(FormContext)
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider")
  }
  return context
}
function updateBehaviorAndErrorState(
  field: FieldConfig,
  formState: FormStateFlat,
  fullFieldId: string,
  behaviorState: BehaviorState,
  errors: ValidationErrors
) {
  if ("value" in field) {
    formState[fullFieldId] = formState[fullFieldId] || field.value // initialFormState should take precedence over field config value
  }
  behaviorState[fullFieldId] = {
    invisible: field.invisible == true,
    disabled: field.disabled == true,
    // value: "value" in field ? field.value : undefined,
  }
  // Initialize errors
  const validationError = validateField(field, formState[fullFieldId])
  if (validationError) {
    errors[fullFieldId] = {
      ...validationError,
      touched: false,
    }
  }
}
//TODO: This can be validateForm (wip)
const processFields = (
  fields: Fields,
  formState: FormStateFlat,
  behaviorState: BehaviorState,
  errors: ValidationErrors,
  parentId = ""
): void => {
  for (const [fieldId, field] of Object.entries(fields)) {
    const fullFieldId = parentId ? `${parentId}.${fieldId}` : fieldId

    if (field.element === "multi_group") {
      const { values, errors: fieldErrors } = MultiGroupField.getInitialState(
        fieldId,
        field as MultiGroupFieldConfig
      )
      Object.assign(formState, values)
      Object.assign(errors, fieldErrors)
    } else if ("fields" in field) {
      processFields(field.fields, formState, behaviorState, errors, fullFieldId)
    } else {
      updateBehaviorAndErrorState(
        field,
        formState,
        fullFieldId,
        behaviorState,
        errors
      )
    }
  }
}

export const createInitialState = (
  fields: Fields,
  order?: string[],
  initialFormState?: FormState
): State => {
  const formState: FormStateFlat = !isNullOrEmpty(initialFormState)
    ? flattenObject(initialFormState)
    : {}
  const behaviorState: BehaviorState = {}
  const errors: ValidationErrors = {}
  const orderedFields: string[] = order || Object.keys(fields)

  processFields(fields, formState, behaviorState, errors)

  return {
    formState,
    behaviorState,
    orderedFields,
    errors: errors,
    isFormValid: false,
  }
}

export const FormProvider = ({
  children,
  fields = {},
  behaviors,
  order,
  size,
  initialFormState,
  onStateChange,
  submitFunction,
  onSubmit,
  onSubmitResponse,
  onSubmitSuccess,
  onSubmitFail,
  logResponse,
  onActionClick,
}: FormProps) => {
  const initialState = useMemo(
    () => createInitialState(fields, order, initialFormState),
    [fields, order, initialFormState]
  )
  const [state, dispatch] = useReducer(reducer, initialState)
  const [fieldsState, setFieldsState] = useState(fields)

  const debouncedDispatch = useMemo(
    () => debounce((action: DispatchAction) => dispatch(action), DEBOUNCE_DELAY),
    [dispatch]
  )
  const debouncedStateChange = useDebouncedCallback(onStateChange, DEBOUNCE_DELAY)

  useEffect(() => {
    if (initialFormState && !isObjectsEqual(state.formState, initialFormState))
      dispatch(setFormState(initialState.formState))
    if (!isObjectsEqual(state.errors, initialState.errors))
      dispatch(setFormErrors(initialState.errors))
  }, [initialFormState])

  useEffect(() => {
    if (!isObjectsEqual(fields, fieldsState)) {
      setFieldsState(fields)
      dispatch(setFormState(initialState.formState)) // reset form if fields changes
      dispatch(setOrderedFields(initialState.orderedFields))
    }
  }, [fields])

  return (
    <FormContext.Provider
      value={{
        initialState,
        state,
        dispatch,
        debouncedDispatch,
        fields: fieldsState,
        behaviors,
        size,
        onStateChange,
        submitFunction,
        onSubmit,
        onSubmitResponse,
        onSubmitSuccess,
        onSubmitFail,
        logResponse,
        debouncedStateChange,
        onActionClick,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
