import React, { useState, useRef, useEffect } from "react"
import { Popover } from "@common/popover"
import { HexColorPicker } from "react-colorful"
import Input from "@common/forms/input"
import Button from "@common/buttons/button"

type ColorPickerProps = {
  color: string
  onChange: (color: string) => void
}
export default function ColorPicker({ color, onChange }: ColorPickerProps) {
  const [pickedColor, setPickedColor] = useState<string>(color) //🚦 HOLDS NEWLY SELECTED COLOR VALUE
  const isColorChanged = !(pickedColor == color) && pickedColor

  function handleClick() {
    if (isColorChanged) onChange(pickedColor)
  }

  useEffect(() => {
    if (color !== pickedColor) setPickedColor(color)
  }, [color])

  return (
    <div className="flex gap-2">
      {renderColorPicker()}
      <p
        key={color}
        className="text-muted-foreground text-sm flex relative my-auto animate-loadMildFadeUp"
      >
        {color || "Not Set"}
      </p>
    </div>
  )

  function renderColorPicker() {
    return (
      <Popover>
        <Popover.Button full={false}>
          <div
            style={{
              backgroundColor: color,
            }}
            className="w-8 h-8 rounded-full cursor-pointer border transform hover:scale-105 "
          />
        </Popover.Button>
        <Popover.Body>
          {({ closePopover }) => renderColorInput(closePopover)}
        </Popover.Body>
      </Popover>
    )
  }

  function renderColorInput(closePopover: () => void) {
    return (
      <div className="p-2 w-52 space-y-4">
        <div className="flex justify-center ">
          <HexColorPicker
            color={pickedColor || undefined}
            onChange={setPickedColor}
          />
        </div>
        <Input
          onChange={(text: string) => setPickedColor(text)}
          value={pickedColor}
          placeholder="#ffffff"
        />
        <div className="flex justify-end gap-2">
          <Button
            onClick={() => {
              isColorChanged ? setPickedColor(color) : closePopover()
            }}
            variant="minimal"
            type="button"
          >
            {isColorChanged ? "Reset" : "Cancel"}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleClick()
              closePopover()
            }}
            disabled={!isColorChanged}
            type="button"
          >
            Save
          </Button>
        </div>
      </div>
    )
  }
}
