import React, { useState } from "react"
import ButtonSubmit, { ButtonSubmitProps } from "@common/v2/buttons/buttonSubmit"
import { useToast } from "@common/toast/toastProvider"
import Alert from "@common/alert"
import { NetworkResponse } from "@common/types"
import { isNullOrEmpty, isArray } from "@common/lib/util"

export interface ButtonSubmitWithAlertProps extends ButtonSubmitProps {
  alert?: boolean
  toast?: boolean
  loadingMsg?: string
  errorMsg?: string
  successMsg?: string
}

export default function ButtonSubmitWithAlert({
  children,
  alert = true,
  toast = false,
  loadingMsg = "Loading..",
  errorMsg = "Something went wrong. Please try again!",
  successMsg = "Success!",
  submitFunction,
  onSubmit,
  onSubmitResponse,
  onSubmitFail,
  onSubmitSuccess,
  ...buttonSubmitProps
}: ButtonSubmitWithAlertProps) {
  const { showToast, updateToast } = useToast()
  const [messages, setMessages] = useState([])
  let toastId: string

  const handleSubmit = () => {
    onSubmit?.()
    setMessages([])
    toastId =
      toast &&
      showToast({
        type: "loading",
        message: loadingMsg,
      })
  }

  const handleSubmitSuccess = (response: NetworkResponse) => {
    if (!response) return
    onSubmitSuccess?.(response)
    toast && updateToast(toastId, { type: "success", message: successMsg })
  }

  const handleSubmitFail = (response: NetworkResponse) => {
    if (!response) return
    onSubmitFail?.(response)
    const msgs = response.message
      ? isArray(response.message)
        ? response.message
        : [response.message]
      : [errorMsg]
    toast && updateToast(toastId, { type: "error", message: msgs.join(",") })
    setMessages(msgs)
  }

  return (
    <>
      <div>
        {alert && !isNullOrEmpty(messages) && (
          <div className="pb-2">
            <Alert variant="error">
              {messages.map((message: string, idx: number) => (
                <p key={idx}>{message}</p>
              ))}
            </Alert>
          </div>
        )}
        <ButtonSubmit
          submitFunction={submitFunction}
          onSubmit={handleSubmit}
          onSubmitResponse={onSubmitResponse}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitFail={handleSubmitFail}
          {...buttonSubmitProps}
        >
          {children}
        </ButtonSubmit>
      </div>
    </>
  )
}
