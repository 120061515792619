import { DispatchAction } from "@common/types"
import {
  FieldValue,
  ValidationError,
  ValidationErrors,
  BehaviorState,
  FormStateFlat,
} from "./types"
import { State } from "./context"

export const SET_FIELD_VALUE = "SET_FIELD_VALUE"
export const SET_FIELD_VALUES = "SET_FIELD_VALUES"
export const SET_FORM_STATE = "SET_FORM_STATE"
export const SET_ERROR = "SET_ERROR"
export const SET_FORM_ERRORS = "SET_FORM_ERRORS"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const RESET_FORM = "RESET_FORM"
export const SET_BEHAVIOR_STATE = "SET_BEHAVIOR_STATE"
export const SET_ORDERED_FIELDS = "SET_ORDERED_FIELDS"

export const setFieldValue = (id: string, value: FieldValue): DispatchAction => ({
  type: SET_FIELD_VALUE,
  payload: { id, value },
})

export const setFieldValues = (partialFormState: FormStateFlat): DispatchAction => ({
  type: SET_FIELD_VALUES,
  payload: { partialFormState },
})

export const setFormState = (formState: FormStateFlat): DispatchAction => ({
  type: SET_FORM_STATE,
  payload: { formState },
})

export const setError = (
  id: string,
  error: ValidationError,
  touched = true
): DispatchAction => ({
  type: SET_ERROR,
  payload: { id, error, touched },
})

export const setFormErrors = (errors: ValidationErrors): DispatchAction => ({
  type: SET_FORM_ERRORS,
  payload: errors,
})

export const clearError = (id: string): DispatchAction => ({
  type: CLEAR_ERROR,
  payload: { id },
})

export const resetForm = (initialState: State): DispatchAction => ({
  type: RESET_FORM,
  payload: initialState,
})

export const setBehaviorState = (behaviorState: BehaviorState): DispatchAction => ({
  type: SET_BEHAVIOR_STATE,
  payload: behaviorState,
})
export const setOrderedFields = (
  orderedFields: State["orderedFields"]
): DispatchAction => ({
  type: SET_ORDERED_FIELDS,
  payload: orderedFields,
})
