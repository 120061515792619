import {
  Behavior,
  Condition,
  Rule,
  Expression,
  Action,
  BehaviorState,
  FormStateFlat,
} from "./types"
import { isNullOrEmpty } from "@common/lib/util"

export function evaluateRule(rule: Rule, formState: FormStateFlat): boolean {
  const fieldValue = formState[rule.field]
  switch (rule.operator) {
    case "equals":
      return fieldValue === rule.value
    case "notEquals":
      return fieldValue !== rule.value
    case "greaterThan":
      return fieldValue > rule.value
    case "lessThan":
      return fieldValue < rule.value
    case "contains":
      return Array.isArray(fieldValue) && fieldValue.includes(rule.value)
    case "notContains":
      return Array.isArray(fieldValue) && !fieldValue.includes(rule.value)
    default:
      return false
  }
}

export function evaluateExpression(
  expression: Expression,
  formState: FormStateFlat
): boolean {
  try {
    return new Function("formState", `return ${expression.expression}`)(formState)
  } catch (error) {
    console.error("Error evaluating expression:", error)
    return false
  }
}

export function evaluateCondition(
  condition: Condition,
  formState: FormStateFlat
): boolean {
  if ("type" in condition && condition.type === "rule") {
    return evaluateRule(condition, formState)
  } else if ("logic" in condition) {
    return evaluateConditions(condition.conditions, condition.logic, formState)
  } else if ("type" in condition && condition.type === "expression") {
    return evaluateExpression(condition, formState)
  }
  return false
}

export function evaluateConditions(
  conditions: Condition[],
  logic: "AND" | "OR" | undefined,
  formState: FormStateFlat
): boolean {
  if (conditions.length === 0) {
    return true
  }

  const evaluateSingleCondition = (condition: Condition): boolean => {
    return evaluateCondition(condition, formState)
  }

  if (logic === "OR") {
    return conditions.some(evaluateSingleCondition)
  } else {
    return conditions.every(evaluateSingleCondition)
  }
}

export function evaluateBehaviors(
  behaviors: Behavior[],
  formState: FormStateFlat
): Action[] {
  return behaviors?.flatMap((behavior) => {
    const conditionMet = evaluateConditions(
      behavior.conditions,
      behavior.logic,
      formState
    )
    return conditionMet ? behavior.actions : getInverseActions(behavior.actions)
  })
}

function getInverseActions(actions: Action[]): Action[] {
  return actions.map((action) => {
    switch (action.type) {
      case "show":
        return { ...action, type: "hide" }
      case "hide":
        return { ...action, type: "show" }
      case "enable":
        return { ...action, type: "disable" }
      case "disable":
        return { ...action, type: "enable" }
      case "setValue":
        return { ...action, type: "clearValue" }
      case "clearValue":
        return { ...action, type: "setValue", value: undefined }
      default:
        return action
    }
  })
}

export function applyActions(
  actions: Action[],
  behaviorState: BehaviorState
): BehaviorState {
  if (isNullOrEmpty(actions)) return behaviorState

  return actions.reduce((newState, action) => {
    switch (action.type) {
      case "setValue":
        return {
          ...newState,
          [action.targetField]: {
            ...newState[action.targetField],
            value: action.value,
          },
        }
      case "clearValue": {
        const targetField = newState[action.targetField] || {}
        const { value, ...rest } = targetField
        return {
          ...newState,
          [action.targetField]: rest,
        }
      }
      case "show":
      case "hide":
        return {
          ...newState,
          [action.targetField]: {
            ...newState[action.targetField],
            invisible: action.type === "hide",
          },
        }
      case "enable":
      case "disable":
        return {
          ...newState,
          [action.targetField]: {
            ...newState[action.targetField],
            disabled: action.type === "disable",
          },
        }
      default:
        return newState
    }
  }, behaviorState)
}

export function applyBehaviors(
  behaviors: Behavior[],
  formState: FormStateFlat,
  behaviorState: BehaviorState
): BehaviorState {
  if (isNullOrEmpty(behaviors)) return behaviorState

  const actions = evaluateBehaviors(behaviors, formState)
  return applyActions(actions, behaviorState)
}
