import { getValue } from "@common/lib/flattenUnflattenObjects"
import { State } from "./context"
import {
  FieldConfig,
  Validation,
  ValidationRule,
  ValidationError,
  ValidationErrors,
  Fields,
} from "./types"
import { isNullOrEmpty, isArray } from "@common/lib/util"

const validationRules: { [key: string]: ValidationRule } = {
  required: (value: any, isRequired): string | null =>
    isRequired &&
    (value === undefined ||
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
      ? "This field is required"
      : null),
  minLength: (value: string, minLength: number): string | null =>
    !value || value.length < minLength ? `Minimum length is ${minLength}` : null,
  maxLength: (value: string, maxLength: number): string | null =>
    !value || value.length > maxLength ? `Maximum length is ${maxLength}` : null,
  pattern: (value: string, pattern: RegExp): string | null =>
    !pattern.test(value) ? "Invalid format" : null,
  min: (value: number, min: number): string | null =>
    value < min ? `Minimum value is ${min}` : null,
  max: (value: number, max: number): string | null =>
    value > max ? `Maximum value is ${max}` : null,
  email: (value: string): string | null =>
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
      ? "Invalid email address"
      : null,
  minItems: (value: any[], minItems: number): string | null =>
    value.length < minItems ? `Minimum ${minItems} items required` : null,
  maxItems: (value: any[], maxItems: number): string | null =>
    value.length > maxItems ? `Maximum ${maxItems} items allowed` : null,
  uniqueItems: (value: any[]): string | null =>
    new Set(value).size !== value.length ? "All items must be unique" : null,
  url: (value: string, isRequired?: boolean) =>
    !isRequired ||
    /^(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9][-a-zA-Z0-9]*(\.[a-zA-Z0-9][-a-zA-Z0-9]*)+(?:\/[^\s]*)?$/.test(
      value
    )
      ? null
      : "Invalid web url",
}

// Define array-specific rules
const arraySpecificRules = new Set(["minItems", "maxItems", "uniqueItems"])

export const addValidationRule = (name: string, rule: ValidationRule) => {
  validationRules[name] = rule
}

export const validateValue = (value: any, validation: Validation): string | null => {
  if (isNullOrEmpty(validation)) return

  for (const [ruleName, ruleOptions] of Object.entries(validation)) {
    if (ruleName in validationRules) {
      const rule = validationRules[ruleName as keyof typeof validationRules]
      const errorMessage = rule(value, ruleOptions)
      if (errorMessage) {
        return errorMessage
      }
    }
  }
  return null
}

export const validateField = (
  field: FieldConfig,
  value: any
): ValidationError | null => {
  if (!field.validation) return null
  const errors: ValidationError = {}

  // Separate array-specific and other validations
  const arrayValidations: Validation = {}
  const otherValidations: Validation = {}

  Object.entries(field.validation).forEach(([ruleName, ruleOptions]) => {
    if (ruleName === "items") return
    if (arraySpecificRules.has(ruleName)) {
      arrayValidations[ruleName] = ruleOptions
    } else {
      otherValidations[ruleName] = ruleOptions
    }
  })

  // Apply array-specific validations if value is an array
  if (isArray(value) && Object.keys(arrayValidations).length > 0) {
    const arrayError = validateValue(value, arrayValidations)
    if (arrayError) {
      errors.message = arrayError
    }
  }

  // If no array-specific errors, apply other validations
  if (!errors.message) {
    const error = validateValue(value, otherValidations)
    if (error) {
      errors.message = error
    }
  }

  // Handle array item validations
  if (isArray(value) && field.validation.items) {
    const itemErrors: ValidationError[] = value
      .map((item: any) =>
        validateField(
          //TODO: test - added all the field's prop since it's throwing type error
          {
            ...field,
            validation: field.validation.items,
          },
          item
        )
      )
      .filter((error): error is ValidationError => error !== null)

    if (itemErrors.length > 0) {
      errors.items = Object.fromEntries(
        itemErrors.map((error, index) => [index, error])
      )
    }
  }

  return Object.keys(errors).length > 0 ? errors : null
}

export function getVisibleErrors(state: State) {
  return Object.keys(state.errors).reduce((errors, id) => {
    if (state.errors[id] != undefined && !state.behaviorState[id]?.invisible) {
      errors[id] = state.errors[id]
      return errors
    }
    return errors
  }, {} as ValidationErrors)
}

export function validateFields(fields: Fields, Values?: Record<string, any>) {
  const errors: ValidationErrors = {}
  if (!fields) return errors
  Object.keys(fields).forEach((fieldId) => {
    const field = fields[fieldId]
    const value = getValue(Values, fieldId) || field.value
    const error = validateField(field, value)
    if (error) errors[fieldId] = error
  })
  return errors
}
