import { DispatchAction } from "@common/types"
import {
  SET_FIELD_VALUE,
  SET_FIELD_VALUES,
  SET_FORM_STATE,
  SET_ERROR,
  SET_FORM_ERRORS,
  CLEAR_ERROR,
  RESET_FORM,
  SET_BEHAVIOR_STATE,
  SET_ORDERED_FIELDS,
} from "./actions"
import { State } from "./context"

export const reducer = (state: State, action: DispatchAction): State => {
  switch (action.type) {
    case SET_FIELD_VALUE:
      return {
        ...state,
        formState: {
          ...state.formState,
          [action.payload.id]: action.payload.value,
        },
      }
    case SET_FIELD_VALUES:
      return {
        ...state,
        formState: {
          ...state.formState,
          ...action.payload.partialFormState,
        },
      }
    case SET_FORM_STATE:
      return {
        ...state,
        formState: { ...action.payload.formState },
      }
    case SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.id]: {
            ...action.payload.error,
            touched: action.payload.touched,
          },
        },
      }
    case SET_FORM_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    case CLEAR_ERROR: {
      const { [action.payload.id]: _, ...restErrors } = state.errors
      return {
        ...state,
        errors: restErrors,
      }
    }
    case RESET_FORM:
      return action.payload
    case SET_BEHAVIOR_STATE:
      return {
        ...state,
        behaviorState: action.payload,
      }
    case SET_ORDERED_FIELDS:
      return {
        ...state,
        orderedFields: action.payload,
      }
    default:
      return state
  }
}
