import React from "react"
import Field from "../field"
import { useFormContext } from "../context"

const DefaultLayout = () => {
  const { fields, state } = useFormContext()
  const { orderedFields } = state
  return (
    <>
      {orderedFields.map((fieldId) => (
        <div key={fieldId} className="mb-6">
          <Field id={fieldId} config={fields[fieldId]} />
        </div>
      ))}
    </>
  )
}

export default DefaultLayout
