import { Header } from "@common/header/headerUnit"
import React, { ReactNode } from "react"

export default function OptionHeader({ display }: { display: ReactNode }) {
  return (
    <div className="px-2 mt-4">
      <Header.ShortHeader color="primary">{display}</Header.ShortHeader>
    </div>
  )
}
