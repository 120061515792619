import ColorPicker from "@common/v2/colorPicker"

type MultiColorPickerProps = {
  colors: string[]
  count: number
  onChange: (pickedColors: any) => any
}
export default function MultiColorPicker({
  colors,
  count,
  onChange,
}: MultiColorPickerProps) {
  const handleColorChange = (color: string, index: number) => {
    const updatedColors = colors ? [...colors] : new Array(count).fill(undefined)
    updatedColors[index] = color
    onChange(updatedColors)
  }

  const paletteCount = colors || new Array(count).fill(undefined)

  return (
    <div className="flex flex-wrap gap-2 md:gap-4 rounded-md border p-2 md:p-4">
      {paletteCount.map((colorCode, index) => (
        <div className="min-w-[8rem]" key={index}>
          <ColorPicker
            color={colorCode}
            onChange={(color) => handleColorChange(color, index)}
          />
        </div>
      ))}
    </div>
  )
}
